import React, { useState } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Button,
  Collapse,
  ListItem,
  ListItemIcon,
  Menu,
  Tooltip,
  makeStyles,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';

const subMenuDisplacement = 34;
const useStyles = makeStyles((theme) => ({
  item: {
    display: 'block',
    paddingTop: 0,
    paddingBottom: 0,
  },
  itemLeaf: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0,
  },
  /* eslint-disable no-nested-ternary */
  button: {
    color: theme.palette.text.secondary,
    padding: '10px 12px',
    justifyContent: 'flex-start',
    borderRadius: '30px',
    textTransform: 'none',
    letterSpacing: 0,
    width: '100%',
    '& .MuiListItemIcon-root': {
      minWidth: 40,
    },
    '& .MuiSvgIcon-root': {
      marginRight: theme.spacing(2),
    },
    '&:hover': {
      backgroundColor: theme.palette.navBar.backgroundColor,
      borderRadius: '30px',
      gap: '40px',
      '& .MuiSvgIcon-root': {
        color: theme.palette.navBar.hoverColor,
      },
      '& span': {
        color: theme.palette.navBar.hoverColor,
      },
    },
  },

  buttonLeaf: {
    color: theme.palette.text.secondary,
    padding: '10px 12px',
    justifyContent: 'flex-start',
    borderRadius: '30px',
    textTransform: 'none',
    letterSpacing: 0,
    width: '100%',
    fontWeight: theme.typography.fontWeightRegular,

    '&:hover': {
      color: theme.palette.navBar.hoverColor,
      fontWeight: 'Bold',
      backgroundColor: theme.palette.navBar.backgroundColor,
    },
    '&.depth-0': {
      '& $title': {
        fontWeight: theme.typography.fontWeightMedium,
      },
    },
    '& .MuiSvgIcon-root': {
      marginRight: theme.spacing(2),
    },
  },
  icon: {
    display: 'flex',
    alignItems: 'center',
  },
  color: {
    color: theme.palette.text.secondary,
  },
  title: {
    marginRight: 'auto',
  },
  active: {
    color: theme.palette.navBar.activeColor,

    '& $title': {
      fontWeight: theme.typography.fontWeightMedium,
    },
    '& $icon': {
      color: theme.palette.secondary.main,
    },
    ...(theme.palette.type === 'light'
      ? {
          '& .MuiTouchRipple-root': {
            color: theme.palette.leftNav.primary,
          },
        }
      : {}),
  },
}));

function NavItem({
  title,
  href,
  depth,
  children,
  icon: Icon,
  className,
  open: openProp,
  info: Info,
  maximized,
  exact,
  ...rest
}) {
  const classes = useStyles();
  const [open, setOpen] = useState(openProp);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleToggle = (event) => {
    if (maximized) {
      setOpen((prevOpen) => !prevOpen);
    } else {
      setAnchorEl(event.currentTarget);
    }
  };

  let paddingLeft = 12;

  if (depth > 0) {
    paddingLeft = subMenuDisplacement + 12 * depth;
  }

  const style = { paddingLeft };
  // maximised menu
  if (children && maximized) {
    return (
      <ListItem
        className={clsx(classes.item, className)}
        disableGutters
        key={title}
        {...rest}
      >
        <Button className={classes.button} onClick={handleToggle} style={style}>
          {Icon && (
            <ListItemIcon>
              <Icon className={clsx(classes.icon, classes.color)} size="20" />
            </ListItemIcon>
          )}
          <span className={clsx(classes.title, classes.color)}>{title}</span>
          {open ? (
            <ExpandLessIcon size="small" className={classes.color} />
          ) : (
            <ExpandMoreIcon size="small" className={classes.color} />
          )}
        </Button>
        <Collapse in={open}>{children}</Collapse>
      </ListItem>
    );
  }

  // minimised menu
  if (children && !maximized) {
    return (
      <ListItem
        className={clsx(classes.item, className)}
        disableGutters
        key={title}
        {...rest}
      >
        <Tooltip title={title} placement="right">
          <Button
            className={classes.button}
            onClick={handleToggle}
            style={style}
          >
            {Icon && (
              <ListItemIcon>
                <Icon className={clsx(classes.icon, classes.color)} />
              </ListItemIcon>
            )}
            <span className={(classes.title, classes.color)}>{title}</span>
            {open ? (
              <ExpandLessIcon size="small" color="inherit" />
            ) : (
              <ExpandMoreIcon size="small" color="inherit" />
            )}
          </Button>
        </Tooltip>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          anchorOrigin={{ horizontal: 'right' }}
          onClose={handleClose}
          onClick={handleClose}
        >
          <div
            style={{
              marginLeft: `-${subMenuDisplacement}px`,
            }}
          >
            {children}
          </div>
        </Menu>
      </ListItem>
    );
  }

  return (
    <ListItem
      className={clsx(classes.itemLeaf, className)}
      disableGutters
      key={title}
      {...rest}
    >
      <Button
        activeClassName={classes.active}
        className={clsx(classes.buttonLeaf, `depth-${depth}`)}
        component={RouterLink}
        exact={exact ?? true}
        style={style}
        to={href}
      >
        {Icon && (
          <ListItemIcon>
            <Icon className={clsx(classes.icon, classes.color)} size="20" />
          </ListItemIcon>
        )}
        <span className={classes.title}>{title}</span>
        {Info && <Info className={classes.info} />}
      </Button>
    </ListItem>
  );
}

NavItem.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  depth: PropTypes.number.isRequired,
  href: PropTypes.string,
  icon: PropTypes.any,
  info: PropTypes.any,
  open: PropTypes.bool,
  title: PropTypes.string.isRequired,
  maximized: PropTypes.bool,
  exact: PropTypes.bool,
};

NavItem.defaultProps = {
  open: false,
};

export default NavItem;
