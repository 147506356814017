/* eslint-disable react/no-array-index-key */
import React, { lazy, Suspense, Fragment } from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';
import _ from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import DashboardLayout from 'src/layouts/DashboardLayout';
import LoadingScreen from 'src/components/LoadingScreen';
import AuthGuard from 'src/components/AuthGuard';
import GuestGuard from 'src/components/GuestGuard';
import { HOME_PAGE_URL, CUSTOMER_ID } from 'src/config';
import { fetchPerms } from './actions/accountActions';
import hasPerm from './utils/hasPerm';
import * as permissions from './constants/permissions';

const routesConfig = [
  {
    exact: true,
    path: '/',
    component: () => <Redirect to="/app" />,
  },
  {
    exact: true,
    path: '/404',
    component: lazy(() => import('src/views/pages/Error404View')),
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/login',
    component: lazy(() => import('src/views/auth/LoginView')),
  },
  {
    path: '/app',
    guard: AuthGuard,
    layout: DashboardLayout,
    routes: [
      {
        exact: true,
        path: '/app',
        component: () => <Redirect to={HOME_PAGE_URL} />,
      },
      {
        exact: true,
        path: '/app/account',
        component: lazy(() => import('src/views/pages/AccountView')),
      },
      {
        exact: true,
        role: '!Valda',
        perm: 'view_dashboard_page',
        path: '/app/boards/map',
        component: lazy(() => import('src/views/boards/map')),
      },
      {
        exact: true,
        role: '!Valda',
        path: '/app/boards/performance',
        component: lazy(() => import('src/views/boards/performance')),
      },
      {
        exact: true,
        path: '/app/boards',
        component: () => <Redirect to={HOME_PAGE_URL} />,
      },
      // report blocks
      {
        exact: true,
        path: '/app/reports/map-block',
        component: lazy(() => import('src/views/reports/MapBlockView')),
      },
      {
        exact: true,
        path: '/app/reports/gas-block',
        component: lazy(() => import('src/views/reports/GasBlockView')),
      },
      {
        exact: true,
        path: '/app/reports/electric-block',
        component: lazy(() => import('src/views/reports/ElectricBlockView')),
      },
      {
        exact: true,
        path: '/app/reports/inventory-block',
        component: lazy(() => import('src/views/reports/InventoryBlockView')),
      },
      {
        exact: true,
        role: '!Valda',
        path: '/app/reports/jobs-block',
        component: lazy(() => import('src/views/reports/JobsBlockView')),
      },
      {
        exact: true,
        role: '!Valda',
        perm: 'view_job_report_page',
        path: '/app/reports/jobs/job',
        component: lazy(() => import('src/views/reports/JobsListView')),
      },
      {
        exact: true,
        role: 'Valda',
        path: '/app/create-job',
        component: lazy(() => import('src/views/pages/ValdaJob/create')),
      },
      {
        exact: true,
        role: 'Valda',
        path: '/app/job-summary',
        component: lazy(() => import('src/views/pages/ValdaJob/view')),
      },
      {
        exact: true,
        role: '!Valda',
        perm: permissions.CREATE_JOB,
        path: '/app/jobs/job/create',
        component: lazy(() => import('src/views/pages/Job/create')),
      },
      {
        exact: true,
        role: '!Valda',
        path: '/app/jobs/job/edit/:id',
        component: lazy(() => import('src/views/pages/Job/edit')),
      },
      {
        exact: true,
        role: '!Valda',
        path: '/app/jobs/job/:jobId',
        component: lazy(() => import('src/views/pages/JobDetailView')),
      },
      {
        exact: true,
        role: '!Valda',
        path: '/app/jobs/job/:jobId/worksheet/:inputWorksheetId',
        component: lazy(() => import('src/views/pages/JobWorksheet')),
      },
      {
        exact: true,
        role: '!Valda',
        path: '/app/imported-files/pre-built-exports',
        component: lazy(() =>
          import('src/views/pages/ImportedFile/PreBuiltExportList')
        ),
      },
      // imported assets
      {
        exact: true,
        role: '!Valda',
        perm: (perms) =>
          !!perms.find((p) =>
            permissions.VIEW_ASSET_IMPORTER_PERMS.includes(p)
          ),
        path: '/app/imported-files/assets',
        extraProps: { category: 'assets' },
        component: lazy(() =>
          import('src/views/pages/ImportedFile/ImportedFileList')
        ),
      },
      {
        exact: true,
        role: '!Valda',
        perm: (perms) =>
          !!perms.find((p) =>
            permissions.VIEW_ASSET_IMPORTER_PERMS.includes(p)
          ),
        path: '/app/imported-files/assets/:importer',
        extraProps: { category: 'assets' },
        component: lazy(() =>
          import('src/views/pages/ImportedFile/ImportedFileList')
        ),
      },
      {
        exact: true,
        role: '!Valda',
        perm: (perms) =>
          !!perms.find((p) =>
            permissions.VIEW_ASSET_IMPORTER_PERMS.includes(p)
          ),
        path: '/app/imported-files/assets/:importer/:id',
        extraProps: { category: 'assets' },
        component: lazy(() =>
          import('src/views/pages/ImportedFile/ImportedFileDetails')
        ),
      },
      // imported jobs
      {
        exact: true,
        role: '!Valda',
        perm: (perms) =>
          !!perms.find((p) => permissions.VIEW_JOB_IMPORTER_PERMS.includes(p)),
        path: '/app/imported-files/jobs',
        extraProps: { category: 'jobs' },
        component: lazy(() =>
          import('src/views/pages/ImportedFile/ImportedFileList')
        ),
      },
      {
        exact: true,
        role: '!Valda',
        perm: (perms) =>
          !!perms.find((p) => permissions.VIEW_JOB_IMPORTER_PERMS.includes(p)),
        path: '/app/imported-files/jobs/:importer',
        extraProps: { category: 'jobs' },
        component: lazy(() =>
          import('src/views/pages/ImportedFile/ImportedFileList')
        ),
      },
      {
        exact: true,
        role: '!Valda',
        perm: (perms) =>
          !!perms.find((p) => permissions.VIEW_JOB_IMPORTER_PERMS.includes(p)),
        path: '/app/imported-files/jobs/:importer/:id',
        extraProps: { category: 'jobs' },
        component: lazy(() =>
          import('src/views/pages/ImportedFile/ImportedFileDetails')
        ),
      },
      // imported vans
      {
        exact: true,
        role: '!Valda',
        perm: (perms) =>
          !!perms.find((p) => permissions.VIEW_VAN_IMPORTER_PERMS.includes(p)),
        path: '/app/imported-files/vans',
        extraProps: { category: 'vans' },
        component: lazy(() =>
          import('src/views/pages/ImportedFile/ImportedFileList')
        ),
      },
      {
        exact: true,
        role: '!Valda',
        perm: (perms) =>
          !!perms.find((p) => permissions.VIEW_VAN_IMPORTER_PERMS.includes(p)),
        path: '/app/imported-files/vans/:importer',
        extraProps: { category: 'vans' },
        component: lazy(() =>
          import('src/views/pages/ImportedFile/ImportedFileList')
        ),
      },
      {
        exact: true,
        role: '!Valda',
        perm: (perms) =>
          !!perms.find((p) => permissions.VIEW_VAN_IMPORTER_PERMS.includes(p)),
        path: '/app/imported-files/vans/:importer/:id',
        extraProps: { category: 'vans' },
        component: lazy(() =>
          import('src/views/pages/ImportedFile/ImportedFileDetails')
        ),
      },
      // imported flows
      {
        exact: true,
        role: '!Valda',
        perm: (perms) =>
          !!perms.find((p) => permissions.VIEW_FLOW_IMPORTER_PERMS.includes(p)),
        path: '/app/imported-files/flows',
        extraProps: { category: 'flows', acceptedFileExt: '.usr' },
        component: lazy(() =>
          import('src/views/pages/ImportedFile/ImportedFileList')
        ),
      },
      {
        exact: true,
        role: '!Valda',
        perm: (perms) =>
          !!perms.find((p) => permissions.VIEW_FLOW_IMPORTER_PERMS.includes(p)),
        path: '/app/imported-files/flows/:importer',
        extraProps: { category: 'flows', acceptedFileExt: '.usr' },
        component: lazy(() =>
          import('src/views/pages/ImportedFile/ImportedFileList')
        ),
      },
      {
        exact: true,
        role: '!Valda',
        perm: (perms) =>
          !!perms.find((p) => permissions.VIEW_FLOW_IMPORTER_PERMS.includes(p)),
        path: '/app/imported-files/flows/:importer/:id',
        extraProps: { category: 'flows' },
        component: lazy(() =>
          import('src/views/pages/ImportedFile/ImportedFileDetails')
        ),
      },
      // users
      {
        exact: true,
        role: '!Valda',
        perm: 'view_manage_users_page',
        path: '/app/users',
        component: lazy(() => import('src/views/pages/User/ListView/UserList')),
      },
      {
        exact: true,
        role: '!Valda',
        perm: 'view_user_audit_logs',
        path: '/app/users-audit',
        component: lazy(() =>
          import('src/views/pages/User/ListView/UserAudit')
        ),
      },
      {
        exact: true,
        perm: 'view_manage_users_page',
        path: '/app/users/roles',
        component: lazy(() => import('src/views/pages/User/roles')),
      },
      {
        exact: true,
        role: '!Valda',
        path: '/app/users/:id',
        component: lazy(() => import('src/views/pages/User/UserDetails')),
      },
      {
        exact: true,
        role: '!Valda',
        path: '/app/dd',
        perm: 'view_data_dictionary',
        component: lazy(() => import('src/views/pages/DD/DDQuestionList')),
      },
      {
        exact: true,
        role: '!Valda',
        perm: 'view_data_dictionary',
        path: '/app/dd/questions/:id',
        component: lazy(() => import('src/views/pages/DD/DDQuestionDetails')),
      },
      {
        exact: true,
        role: '!Valda',
        path: '/app/reports/jobs/job',
        component: lazy(() => import('src/views/reports/JobsListView')),
      },
      {
        exact: true,
        path: '/app/reports/commission',
        perm: 'view_commission_report_page',
        component: lazy(() => import('src/views/reports/Commission')),
      },
      {
        exact: true,
        path: '/app/reports/material-numbers',
        perm: 'view_material_number_report_page',
        component: lazy(() => import('src/views/reports/MaterialNumbers')),
      },

      // end of report blocks
      {
        exact: true,
        path: '/app/reports/mpan-report',
        customers: ['dev'],
        perm: 'view_mpan_report_page',
        component: lazy(() => import('src/views/reports/MpanListView')),
      },
      {
        exact: true,
        path: '/app/reports/mprn-report',
        component: lazy(() => import('src/views/reports/MprnListView')),
      },
      {
        exact: true,
        path: '/app/reports/inventory/asset-details',
        perm: 'view_asset_report_page',
        component: lazy(() =>
          import('src/views/reports/InventoryAssetDetailsListView')
        ),
      },
      {
        exact: true,
        path: '/app/inventory/asset',
        component: lazy(() => import('src/views/pages/Asset/view')),
      },
      {
        exact: true,
        path: '/app/reports/appointment-report',
        component: lazy(() =>
          import('src/views/reports/AppointedMprnListView')
        ),
      },
      {
        exact: true,
        path: '/app/reports/rejected-jobs-report',
        component: lazy(() => import('src/views/reports/RejectedJobsListView')),
      },
      {
        exact: true,
        path: '/app/reports/rejected-flows-report',
        component: lazy(() => import('src/views/reports/RejectedFlowListView')),
      },
      {
        exact: true,
        perm: 'view_job_outcome_page',
        path: '/app/reports/job-outcome',
        component: lazy(() => import('src/views/reports/JobOutcomeListView')),
      },
      {
        exact: true,
        perm: 'view_schedule_page',
        path: '/app/reports/jobs/board',
        component: lazy(() => import('src/views/reports/JobsBoard')),
      },
      {
        exact: true,
        // customers: ['maas'],
        perm: 'view_flow_records',
        path: '/app/flows/industry-flow-tracking',
        component: lazy(() => import('src/views/pages/Flow/FlowRecordList')),
      },
      {
        exact: true,
        perm: 'view_flow_triage_page',
        path: '/app/flows/flow-triage',
        component: lazy(() =>
          import('src/views/pages/Flow/FlowTriageRecordList')
        ),
      },
      {
        exact: true,
        // customers: ['maas'],
        perm: 'view_flow_records',
        path: '/app/flows/records/:id',
        component: lazy(() => import('src/views/pages/Flow/FlowRecordDetails')),
      },

      {
        exact: true,
        // customers: ['maas'],
        perm: 'view_flowcontainer',
        path: '/app/flows/dataflow-exceptions',
        extraProps: { status: 'nack_enack_received' },
        component: lazy(() => import('src/views/pages/Flow/FlowContainerList')),
      },
      {
        exact: true,
        // customers: ['maas'],
        perm: 'view_flowcontainer',
        path: '/app/flows/containers/:id',
        component: lazy(() =>
          import('src/views/pages/Flow/FlowContainerEditor')
        ),
      },

      {
        exact: true,
        customers: ['maas'],
        perm: 'view_flow_builder_page',
        path: '/app/flows/flow-builder',
        component: lazy(() => import('src/views/reports/FlowBuilder')),
      },
      {
        exact: true,
        customers: ['maas'],
        perm: 'view_dataflow_exceptions_page',
        path: '/app/reports/dataflow-exceptions',
        component: lazy(() => import('src/views/reports/DataflowExceptions')),
      },
      {
        exact: true,
        customers: ['maas', 'dev'],
        perm: 'view_activity_report',
        path: '/app/reports/activity',
        component: lazy(() => import('src/views/reports/Activity')),
      },
      {
        exact: true,
        path: '/app/map/asset',
        component: lazy(() => import('src/views/reports/MapAssetListView')),
      },
      {
        exact: true,
        path: '/app/map/asset/edit/:id',
        component: lazy(() => import('src/views/pages/MapAsset/edit')),
      },
      {
        exact: true,
        path: '/app/map/asset/:assetId',
        component: lazy(() => import('src/views/pages/MapAssetDetailView')),
      },
      {
        exact: true,
        path: '/app/map/portfolio',
        component: lazy(() => import('src/views/reports/MapPortfolioListView')),
      },
      {
        exact: true,
        path: '/app/map/meter-variant',
        component: lazy(() => import('src/views/reports/MeterVariantListView')),
      },
      {
        exact: true,
        path: '/app/map/meter-variant/create',
        component: lazy(() => import('src/views/pages/MeterVariant/create')),
      },
      {
        exact: true,
        path: '/app/map/meter-variant/edit/:variant_ref',
        component: lazy(() => import('src/views/pages/MeterVariant/edit')),
      },
      {
        exact: true,
        path: '/app/map/moa',
        component: lazy(() => import('src/views/reports/MOAListView')),
      },
      {
        exact: true,
        path: '/app/map/moa/create',
        component: lazy(() => import('src/views/pages/MOA/create')),
      },
      {
        exact: true,
        path: '/app/map/moa/edit/:id',
        component: lazy(() => import('src/views/pages/MOA/edit')),
      },
      {
        exact: true,
        path: '/app/map/moa-contract',
        component: lazy(() => import('src/views/reports/MOAContractListView')),
      },
      {
        exact: true,
        path: '/app/map/moa-contract/create',
        component: lazy(() => import('src/views/pages/MOAContract/create')),
      },
      {
        exact: true,
        path: '/app/map/moa-contract/edit/:contractRef',
        component: lazy(() => import('src/views/pages/MOAContract/edit')),
      },
      {
        exact: true,
        path: '/app/map/moa-schedule',
        component: lazy(() => import('src/views/reports/MOAScheduleListView')),
      },
      {
        exact: true,
        path: '/app/map/churn',
        component: lazy(() => import('src/views/reports/MapChurnListView')),
      },
      {
        exact: true,
        path: '/app/map/supplier',
        component: lazy(() => import('src/views/reports/MapSuppliersListView')),
      },
      {
        exact: true,
        path: '/app/map/supplier/create',
        component: lazy(() => import('src/views/pages/MapSupplier/create')),
      },
      {
        exact: true,
        path: '/app/map/supplier/edit/:supplierId',
        component: lazy(() => import('src/views/pages/MapSupplier/edit')),
      },
      {
        exact: true,
        path: '/app/map/supplier/:supplierId',
        component: lazy(() => import('src/views/pages/MapSupplier/DetailView')),
      },
      {
        exact: true,
        path: '/app/map/supplier-contract',
        component: lazy(() =>
          import('src/views/reports/SupplierContractListView')
        ),
      },
      {
        exact: true,
        path: '/app/map/supplier-contract/create',
        component: lazy(() =>
          import('src/views/pages/SupplierContract/create')
        ),
      },
      {
        exact: true,
        path: '/app/map/supplier-contract/edit/:contractRef',
        component: lazy(() => import('src/views/pages/SupplierContract/edit')),
      },
      {
        exact: true,
        path: '/app/map/billing',
        component: lazy(() => import('src/views/reports/MapBillingListView')),
      },
      {
        exact: true,
        path: '/app/map/billing-global-update',
        component: lazy(() =>
          import('src/views/reports/MapBillingGlobalUpdate')
        ),
      },
      {
        exact: true,
        path: '/app/mpan/:mpanId',
        component: lazy(() => import('src/views/pages/MpanDetailsView')),
      },
      {
        exact: true,
        path: '/app/mprn/:mprnId',
        component: lazy(() => import('src/views/pages/MprnDetailsView')),
      },
      {
        exact: true,
        path: '/app/asset/:assetId',
        component: lazy(() => import('src/views/pages/AssetDetailsView')),
      },
      {
        exact: true,
        path: '/app/reidrect/:serialNumber',
        component: lazy(() =>
          import('src/views/pages/SerialNumberRedirectView')
        ),
      },
      {
        exact: true,
        path: '/app/map/supplier-schedule',
        component: lazy(() =>
          import('src/views/reports/SupplierScheduleListView')
        ),
      },
      {
        exact: true,
        path: '/app/map-reporting/electric',
        component: lazy(() =>
          import('src/views/reports/MapReportingElectricBillingListView')
        ),
      },
      {
        exact: true,
        path: '/app/map-reporting/gas',
        component: lazy(() =>
          import('src/views/reports/MapReportingGasBillingListView')
        ),
      },
      {
        exact: true,
        path: '/app/map-reporting/exceptions',
        component: lazy(() =>
          import('src/views/reports/MapReportingExceptionsListView')
        ),
      },
      {
        exact: true,
        path: '/app/reports/vans/van',
        perm: 'view_van_report_page',
        component: lazy(() => import('src/views/reports/VanListView')),
      },
      {
        exact: true,
        path: '/app/reports/vans/van-accuracy',
        perm: 'view_van_audit_accuracy',
        component: lazy(() =>
          import('src/views/pages/VanAuditAccuracy/VanAuditAccuracyView')
        ),
      },
      {
        exact: true,
        path: '/app/reports/vans/van-job-details/:jobId',
        perm: 'view_van_audit_job_details',
        component: lazy(() =>
          import('src/views/pages/VanAuditJobDetails/VanAuditJobDetailsView')
        ),
      },
      {
        exact: true,
        role: '!Valda',
        path: '/app/vans/:id',
        component: lazy(() => import('src/views/pages/Van/VanDetails')),
      },
      {
        exact: true,
        role: '!Valda',
        customers: ['dev'],
        path: '/app/reports/electric/rejections',
        component: lazy(() =>
          import('src/views/reports/ElectricRejectionsReport')
        ),
      },
      {
        exact: true,
        customers: ['dev', 'maas'],
        perm: 'view_notificationconfig',
        path: '/app/notifications/manage',
        component: lazy(() =>
          import('src/views/reports/ManageNotificationView')
        ),
      },
      {
        component: () => <Redirect to="/404" />,
      },
    ],
  },
];

const renderComponent = (route, roles, perms, isStaff, props) => {
  const role = route.role || 'any';
  const roleCheckRequired = role !== 'any';
  const roleCheckPassed =
    !roleCheckRequired || role.startsWith('!')
      ? !roles.includes(role.substring(1))
      : roles.includes(role);

  const permCheckPassed = route.perm ? hasPerm(route.perm, perms) : true;
  const customerCheckPassed = route.customers?.length
    ? route.customers.includes(CUSTOMER_ID)
    : true;

  let defaultPage = HOME_PAGE_URL;
  if (roles.includes('Valda')) {
    defaultPage = '/app/create-job';
  }
  const isHomePage = route.path === defaultPage;

  const staffOverride = route.staffOnly ? isStaff : undefined;

  const isAllowed =
    staffOverride ??
    (isHomePage || (roleCheckPassed && permCheckPassed && customerCheckPassed));

  if (!isAllowed) {
    return <Redirect to={defaultPage} />;
  }

  const Component = route.component;
  const withExtraProps = { ...props, ...(route.extraProps || {}) };
  return <Component {...withExtraProps} />;
};

const renderRoutes = (routes, roles, perms, isStaff) =>
  routes ? (
    <Suspense fallback={<LoadingScreen />}>
      <Switch>
        {routes.map((route, i) => {
          const Guard = route.guard || Fragment;
          const Layout = route.layout || Fragment;

          return (
            <Route
              key={i}
              path={route.path}
              exact={route.exact}
              render={(props) => (
                <Guard>
                  <Layout>
                    {route.routes
                      ? renderRoutes(route.routes, roles, perms, isStaff)
                      : renderComponent(route, roles, perms, isStaff, props)}
                  </Layout>
                </Guard>
              )}
            />
          );
        })}
      </Switch>
    </Suspense>
  ) : null;

function Routes() {
  const dispatch = useDispatch();
  const account = useSelector((state) => state.account);

  React.useEffect(() => {
    dispatch(fetchPerms());
  }, []);

  return account.perms.loaded ? (
    renderRoutes(
      routesConfig,
      _.get(account.user, 'https://umesh.energy/roles', []),
      account.perms.perms,
      account.user?.is_superuser
    )
  ) : (
    <LoadingScreen />
  );
}

export default Routes;
