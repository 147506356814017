export const THEMES = {
  LIGHT: 'LIGHT',
  DARK: 'DARK',
};

export const COLORS = Object.freeze({
  // Correla colors
  MIDNIGHT: '#1E1246',
  BLACK: '#0a0a0a',
  PORCELAIN: '#F5F5F5',
  SKY: '#218CCC',
  GOLDEN: '#FFBB1A',
  MINT: '#2BB573',
  CHILLI: '#E51C28',
  WHITE: '#FFFFFF',
  GREY: '#878b91',
  DEFAULT_LIGHT: '#f4f4f4',
  LAVENDER_GREY: '#CAC4CF',
  SOFT_ORCHID: '#FFFBFF',
  SKY_GREY: '#E1E3E3',
  // Waypoint colors
  DARK_GREY: '#808080',
  MIDNIGHT_LIGHT: '#8E88A2',
  PORCELAIN_DARK: '#E5E5E5',
  WAYPOINT_LIGHT_PRIMARY: '#6351A6',
  CHARCOAL: '#1D1B20',
  SLATE_GREY: '#48454E',
  SOFT_LAVENDER: '#E7DFF8',
  MIDNIGHT_NAVY: '#26252C',
  WAYPOINT_DARK_PRIMARY: '#CCBEFF',
  SILVER_MIST: '#938F99',
  PALE_STEEL: '#C4C7C7',
  SHADOWY_BLACK: '#373638a3',
  AZURE_SMOKE: '#2e2e2ec4',
  STORMY_INDIGO: '#494458',
  WAYPOINT_DARK: '#212028',
  CARBON_GREY: '#5c5a62',
});

/** choices for `Question.type` field */
export const QUESTION_TYPES = Object.freeze({
  API_CALL: 'API Call',
  BARCODE_SCAN: 'Barcode Scan',
  CURRENCY: 'Currency',
  DATE: 'Date',
  DATETIME: 'DateTime',
  DISPLAY_ONLY_WITH_HISTORY: 'Display Only with History',
  DISPLAY_ONLY: 'Display Only',
  DROPDOWN_LIST: 'Dropdown List',
  ELECTRIC_BARCODE_SCAN: 'Electric barcode scan',
  GAS_BARCODE_SCAN: 'Gas barcode scan',
  GUID: 'GUID',
  IGNORE_QID: 'Ignore QID',
  INTEGER: 'Integer',
  INVENTORY: 'Inventory',
  METER_READING: 'Meter Reading',
  MULTI_SELECT: 'Multi Select',
  NO_OF_READS: 'No of Reads',
  PHONE: 'Phone',
  PHOTO: 'Photo',
  SIGNATURE: 'Signature',
  TEXT_WITH_HISTORY: 'Text with History',
  TEXT: 'Text',
  TIME: 'Time',
  YES_NO: 'Yes/No',
});

/** choices for `QuestionCondition.action_condition` field */
export const QUESTION_COND_ACTIONS = Object.freeze({
  HIDE_QUESTION: 'hide_question',
  SHOW_QUESTION: 'show_question',
  HIDE_WORKSHEET: 'hide_worksheet',
  SHOW_WORKSHEET: 'show_worksheet',
  SET_ANSWER: 'set_answer',
});

export const DEVICE_TYPES = Object.freeze({
  esme: 'ESME',
  gsme: 'GSME',
  ppmid: 'PPMID',
  reg: 'REG',
  chub: 'CHUB',
  ihd: 'IHD',
  edme: 'EDME',
  gdme: 'GDME',
  adpt: 'ADPT',
});

export const JOB_STATUS_CODES = {
  PROCESSING_D_FLOWS: 200,
  INDUSTRY_FLOWS_CREATED: 103,
  PROCESSING_RGMA_FLOWS: 102,
  COMPLETED_DATA_SENT: 101,
  ABORTED: 100,
  COMPLETED: 99,
  EN_ROUTE: 25,
  OPEN: 20,
  CANCELLED: 12,
  MOA_CANCELLATION: 11,
  DEVICES_AVAILABLE: 10,
  ENGINEER_DROPOFF: 9,
  DEVICES_ON_VAN: 8,
  ENGINEER_PICKUP: 7,
  WORK_ORDER_RECEIVED: 6,
  STARTED: 5,
  CWS_RECEIVED: 4,
  REJECTED: 3,
  SENT_JW_REQUEST: 2,
  JOB_RECEIVED: 1,
};
