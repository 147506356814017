// jobs
export const CREATE_JOB = 'view_add_job_page';
export const RESCHEDULE_JOB = 'reschedule_job';
export const CANCEL_JOB = 'cancel_job';
export const ASSIGN_JOB_ENGINEER = 'assign_job_engineer';
export const EDIT_JOB_ENGINEER = 'edit_job_engineer';
export const ADD_JOB_NOTE = 'add_note';
export const EDIT_CWS = 'edit_cws'; // CWS = customer worksheet
export const BULK_CANCEL_JOB = 'bulk_cancel_jobs';
export const BULK_ADD_NOTE = 'bulk_add_notes';
export const OVERRIDE_CAPACITY = 'override_capacity';

// jobs (non-metering)
export const CREATE_VAN_AUDIT_JOB = 'add_van_audit_job';
export const CANCEL_NON_METERING_JOB = 'cancel_non_metering_job';

// material numbers
export const ADD_MATERIAL_NUMBER = 'add_devicetype';

// vans
export const ADD_VAN = 'add_van';
export const EDIT_VAN = 'change_van';

// imported files (assets)
export const VIEW_IMPORTED_ASNS = 'view_imported_asns';
export const VIEW_IMPORTED_REJECTED_ASNS = 'view_imported_rejected_asns';
export const VIEW_IMPORTED_THIRD_PARTY_ASSETS =
  'view_imported_third_party_assets';
export const VIEW_IMPORTED_CO = 'view_imported_collection_orders';
export const VIEW_IMPORTED_EXPECTED_RETURNS = 'view_imported_expected_returns';
export const VIEW_IMPORTED_UNEXPECTED_RETURNS =
  'view_imported_unexpected_returns';
export const VIEW_IMPORTED_ASSET_UPDATES = 'view_imported_asset_updates';
// imported files (jobs)
export const VIEW_IMPORTED_JOB_UPDATES = 'view_imported_job_updates';
export const VIEW_IMPORTED_JOB_CANCEL = 'view_imported_job_cancel';
// imported files (vans)
export const VIEW_IMPORTED_VANS = 'view_imported_vans';
export const VIEW_IMPORTED_VAN_ASSIGNMENTS = 'view_imported_van_assignments';
export const VIEW_VAN_AUDIT_ACCURACY = 'view_van_audit_accuracy';
// imported files (flows)
export const VIEW_IMPORTED_D2036 = 'view_imported_d2036';
export const VIEW_IMPORTED_D2037 = 'view_imported_d2037';

export const VIEW_PREBUILT_EXPORTS_ASSETS = 'view_prebuilt_exports_asset';
export const VIEW_PREBUILT_EXPORTS_MEM = 'view_prebuilt_exports_mem';
export const VIEW_PREBUILT_EXPORTS_METERING_JOBS =
  'view_prebuilt_exports_metering_jobs';
export const VIEW_PREBUILT_EXPORTS_NONMETERING_JOBS =
  'view_prebuilt_exports_nonmetering_jobs';
export const DOWNLOAD_PREBUILT_EXPORTS = 'download_prebuilt_exports';

export const VIEW_PREBUILT_EXPORTS_PERMS = [
  VIEW_PREBUILT_EXPORTS_ASSETS,
  VIEW_PREBUILT_EXPORTS_MEM,
  VIEW_PREBUILT_EXPORTS_METERING_JOBS,
  VIEW_PREBUILT_EXPORTS_NONMETERING_JOBS,
];

export const VIEW_ASSET_IMPORTER_PERMS = [
  VIEW_IMPORTED_ASNS,
  VIEW_IMPORTED_REJECTED_ASNS,
  VIEW_IMPORTED_THIRD_PARTY_ASSETS,
  VIEW_IMPORTED_CO,
  VIEW_IMPORTED_EXPECTED_RETURNS,
  VIEW_IMPORTED_UNEXPECTED_RETURNS,
  VIEW_IMPORTED_ASSET_UPDATES,
];

export const VIEW_JOB_IMPORTER_PERMS = [
  VIEW_IMPORTED_JOB_UPDATES,
  VIEW_IMPORTED_JOB_CANCEL,
];

export const VIEW_VAN_IMPORTER_PERMS = [
  VIEW_IMPORTED_VANS,
  VIEW_IMPORTED_VAN_ASSIGNMENTS,
];

export const VIEW_FLOW_IMPORTER_PERMS = [
  VIEW_IMPORTED_D2036,
  VIEW_IMPORTED_D2037,
];
