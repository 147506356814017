/* eslint-disable no-use-before-define */
/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { useLocation, matchPath } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useSelector } from 'react-redux';
import { Box, Divider, Hidden, List } from '@material-ui/core';
import {
  Dashboard as DashboardIcon,
  Queue as QueueIcon,
  Edit as EditIcon,
} from '@material-ui/icons';
import { WideLogo } from 'src/components/Logo';
import {
  MAP_ACTIVE,
  MAP_REPORT_ACTIVE,
  GAS_ACTIVE,
  ELECTRIC_ACTIVE,
  INVENTORY_ACTIVE,
  JOBS_ACTIVE,
  VANS_ACTIVE,
  CUSTOMER_ID,
} from 'src/config';
import hasPerm from 'src/utils/hasPerm';
import * as permissions from 'src/constants/permissions';
import { WAYPOINT_ICON_COMPONENT } from 'src/constants/icons';
import NavItem from './NavItem';

const navConfig = [
  {
    subheader: 'Dashboards',
    items: getDashboardsNav(),
  },
  getReportsNav(),
];

function getDashboardsNav() {
  const items = [];
  if (MAP_ACTIVE) {
    items.push({
      title: 'Dashboard',
      icon: DashboardIcon,
      href: '/app/boards/map',
      perm: 'view_dashboard_page',
    });
  }
  if (CUSTOMER_ID === 'eon') {
    items.push({
      title: 'Performance Dashboard',
      icon: DashboardIcon,
      href: '/app/boards/performance',
    });
  }
  return items;
}

function getReportsNav() {
  const items = [];
  if (MAP_ACTIVE) {
    items.push({
      title: 'MAP',
      icon: WAYPOINT_ICON_COMPONENT.MAP_MODULE,
      items: [
        {
          title: 'Asset',
          href: '/app/map/asset',
        },
        {
          title: 'Portfolio',
          href: '/app/map/portfolio',
        },
        {
          title: 'Churn',
          href: '/app/map/churn',
        },
        {
          title: 'Suppliers',
          href: '/app/map/supplier',
        },
        {
          title: 'Supplier Contract',
          href: '/app/map/supplier-contract',
        },
        {
          title: 'Supplier Schedule',
          href: '/app/map/supplier-schedule',
        },
        {
          title: 'Billing',
          href: '/app/map/billing',
        },
        {
          title: 'Billing Global Update',
          href: '/app/map/billing-global-update',
        },
        {
          title: 'Meter Variant',
          href: '/app/map/meter-variant',
        },
        {
          title: 'MOA',
          href: '/app/map/moa',
        },
        {
          title: 'MOA Contract',
          href: '/app/map/moa-contract',
        },
        {
          title: 'MOA Schedule',
          href: '/app/map/moa-schedule',
        },
      ],
    });
  }

  if (MAP_REPORT_ACTIVE) {
    items.push({
      title: 'MAP Reports',
      icon: WAYPOINT_ICON_COMPONENT.MAP_MODULE,
      items: [
        {
          title: 'Electric Billing',
          href: '/app/map-reporting/electric',
        },
        {
          title: 'Gas Billing',
          href: '/app/map-reporting/gas',
        },
        {
          title: 'Map Reporting Exceptions',
          href: '/app/map-reporting/exceptions',
        },
      ],
    });
  }

  if (GAS_ACTIVE) {
    items.push({
      title: 'Gas',
      icon: WAYPOINT_ICON_COMPONENT.GAS_MODULE,
      items: [
        {
          title: 'MPRN Report',
          href: '/app/reports/mprn-report',
        },
        {
          title: 'Appointed MPRN Report',
          href: '/app/reports/appointment-report',
        },
      ],
    });
  }

  if (ELECTRIC_ACTIVE) {
    items.push({
      title: 'MEM',
      icon: WAYPOINT_ICON_COMPONENT.MEM_MODULE,
      items: [
        ...(['dev'].includes(CUSTOMER_ID)
          ? [
              {
                title: 'MPAN Report',
                href: '/app/reports/mpan-report',
                perm: 'view_mpan_report_page',
              },
              {
                title: 'Rejections Report',
                href: '/app/reports/electric/rejections',
              },
            ]
          : []),
        // {
        //   title: 'Industry Flow Tracking',
        //   href: '/app/reports/industry-flow-tracking',
        //   perm: 'view_industry_flows_page'
        // },
        // {
        //   title: 'Dataflow Exceptions',
        //   href: '/app/reports/dataflow-exceptions',
        //   perm: 'view_dataflow_exceptions_page',
        // },
        {
          title: 'Industry Flow Tracking',
          href: '/app/flows/industry-flow-tracking',
          perm: 'view_flow_records',
        },
        {
          title: 'Dataflow Exceptions',
          href: '/app/flows/dataflow-exceptions',
          perm: 'view_flowcontainer',
        },
        ...(['maas'].includes(CUSTOMER_ID)
          ? [
              {
                title: 'Flow Builder',
                href: '/app/flows/flow-builder',
                perm: 'view_flow_builder_page',
              },
            ]
          : []),
        {
          title: 'Flow Triage',
          href: '/app/flows/flow-triage',
          perm: 'view_flow_triage_page',
        },
      ],
    });
  }

  if (INVENTORY_ACTIVE) {
    items.push({
      title: 'Assets',
      icon: WAYPOINT_ICON_COMPONENT.ASSETS_MODULE,
      items: [
        {
          title: 'Asset Report',
          href: '/app/reports/inventory/asset-details',
          perm: 'view_asset_report_page',
        },
        ...(VANS_ACTIVE
          ? [
              {
                title: 'Van Report',
                href: '/app/reports/vans/van',
                perm: 'view_van_report_page',
              },
              {
                title: 'Van Audit Accuracy',
                href: '/app/reports/vans/van-accuracy',
                perm: permissions.VIEW_VAN_AUDIT_ACCURACY,
              },
            ]
          : []),
        {
          title: 'Commission Report',
          href: '/app/reports/commission',
          perm: 'view_commission_report_page',
        },
      ],
    });
  }

  items.push({
    title: 'Bookings',
    icon: QueueIcon,
    items: [
      {
        title: 'Add New Job',
        href: '/app/jobs/job/create',
        perm: permissions.CREATE_JOB,
      },
    ],
  });

  if (JOBS_ACTIVE) {
    items.push({
      title: 'Job Management',
      icon: WAYPOINT_ICON_COMPONENT.JOBS_MODULE,
      items: [
        {
          title: 'Job Report',
          href: '/app/reports/jobs/job',
          perm: 'view_job_report_page',
        },
        {
          title: 'Job Outcome',
          href: '/app/reports/job-outcome',
          perm: 'view_job_outcome_page',
        },
        {
          title: 'Schedule',
          href: '/app/reports/jobs/board',
          perm: 'view_schedule_page',
        },
        ...(['maas', 'dev'].includes(CUSTOMER_ID)
          ? [
              {
                title: 'Activity Report',
                href: '/app/reports/activity',
                perm: 'view_activity_report',
              },
            ]
          : []),
      ],
    });

    items.push({
      title: 'File Management',
      icon: WAYPOINT_ICON_COMPONENT.FILES_MODULE,
      items: [
        {
          title: 'Pre-Built Exports',
          href: '/app/imported-files/pre-built-exports',
          perm: (perms) =>
            !!perms.find((p) =>
              permissions.VIEW_PREBUILT_EXPORTS_PERMS.includes(p)
            ),
          exact: false,
        },
        {
          title: 'Asset Importers',
          href: '/app/imported-files/assets',
          perm: (perms) =>
            !!perms.find((p) =>
              permissions.VIEW_ASSET_IMPORTER_PERMS.includes(p)
            ),
          exact: false,
        },
        {
          title: 'Flow Importers',
          href: '/app/imported-files/flows',
          perm: (perms) =>
            !!perms.find((p) =>
              permissions.VIEW_FLOW_IMPORTER_PERMS.includes(p)
            ),
          exact: false,
        },
        ...(JOBS_ACTIVE
          ? [
              {
                title: 'Job Importers',
                href: '/app/imported-files/jobs',
                perm: (perms) =>
                  !!perms.find((p) =>
                    permissions.VIEW_JOB_IMPORTER_PERMS.includes(p)
                  ),
                exact: false,
              },
            ]
          : []),
        ...(VANS_ACTIVE
          ? [
              {
                title: 'Van Importers',
                href: '/app/imported-files/vans',
                perm: (perms) =>
                  !!perms.find((p) =>
                    permissions.VIEW_VAN_IMPORTER_PERMS.includes(p)
                  ),
                exact: false,
              },
            ]
          : []),
      ],
    });

    items.push({
      title: 'User Admin',
      icon: WAYPOINT_ICON_COMPONENT.USERS_MODULE,
      items: [
        {
          title: 'Manage Users',
          href: '/app/users',
          perm: 'view_manage_users_page',
        },
        {
          title: 'Manage Notifications',
          href: '/app/notifications/manage',
          perm: 'view_notificationconfig',
        },
        {
          title: 'User Audit Logs',
          href: '/app/users-audit',
          perm: 'view_user_audit_logs',
        },
        ...(['dev', 'eon'].includes(CUSTOMER_ID)
          ? [
              {
                title: 'User Roles',
                href: '/app/users/roles',
                perm: 'view_manage_users_page',
              },
            ]
          : []),
      ],
    });
  }

  if (CUSTOMER_ID === 'maas') {
    items.push({
      title: 'Configuration',
      icon: EditIcon,
      items: [
        {
          title: 'Material Numbers',
          href: '/app/reports/material-numbers',
          perm: 'view_material_number_report_page',
        },
      ],
    });
  }

  items.push({
    title: 'Data Dictionary',
    icon: WAYPOINT_ICON_COMPONENT.DD_MODULE,
    items: [
      {
        title: 'View DD',
        href: '/app/dd',
        perm: 'view_data_dictionary',
      },
    ],
  });

  return {
    subheader: 'Reports',
    items,
  };
}

function Nav({ maximized }) {
  const location = useLocation();
  const perms = useSelector((state) => state.account.perms.perms);

  function renderNavItems({ items, ...rest }) {
    const navItems = items.reduce(
      (acc, item) => reduceChildRoutes({ acc, item, ...rest }),
      []
    );
    return navItems.length ? <List disablePadding>{navItems}</List> : null;
  }

  function reduceChildRoutes({ acc, pathname, item, depth = 0 }) {
    const key = item.title + depth;

    if (item.items) {
      const open = item.items.find((subitem) =>
        matchPath(pathname, {
          path: subitem.href,
          exact: false,
        })
      );

      const childNavItems = renderNavItems({
        depth: depth + 1,
        pathname,
        items: item.items,
      });

      if (childNavItems) {
        acc.push(
          <NavItem
            depth={depth}
            icon={item.icon}
            key={key}
            info={item.info}
            title={item.title}
            open={Boolean(open)}
            maximized={maximized}
          >
            {childNavItems}
          </NavItem>
        );
      }
    } else if (!item.perm || hasPerm(item.perm, perms)) {
      acc.push(
        <NavItem
          depth={depth}
          href={item.href}
          exact={item.exact}
          icon={item.icon}
          key={key}
          info={item.info}
          title={item.title}
        />
      );
    }

    return acc;
  }

  return (
    <Box height="auto" display="flex" flexDirection="column">
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Hidden lgUp>
          <Box p={2} display="flex" justifyContent="center">
            <RouterLink to="/">
              <WideLogo />
            </RouterLink>
          </Box>
          <Divider />
        </Hidden>
        <Box>
          {navConfig.map((config) => (
            <React.Fragment key={`fragment-${config.subheader}`}>
              {renderNavItems({
                items: config.items,
                pathname: location.pathname,
              })}
              <Divider />
            </React.Fragment>
          ))}
        </Box>
      </PerfectScrollbar>
    </Box>
  );
}

Nav.propTypes = {
  maximized: PropTypes.bool,
};

export default Nav;
export { navConfig };
